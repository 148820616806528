<template>
  <Toast />

  <div class="card">
    <DataTable
      ref="dt"
      :value="records"
      dataKey="id"
      :paginator="true"
      :rows="10"
      sortField="subtitle"
      :sortOrder="-1"
      class="dt"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[5, 10, 25]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
      v-model:filters="filters"
      :globalFilterFields="['title','subtitle','keywords','author']"
      stateStorage="session" stateKey="piekirpoints"
    >
      <template #header>
        <div class="table-header pop20">
          <div class="p-d-flex p-jc-between">
            <div>Manage piekirpedia points</div>
            <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                        </span>
          </div>
        </div>
      </template>

      <Column
        field="id"
        header="Id"
        :sortable="true"
        headerStyle="width: 5%"
      ></Column>
      <Column header="Image">
        <template #body="slotProps">
          <img
            :src="
              `${$config.images.server}/images/${slotProps.data.media}?width=80&height=80&fit=contain&background=ffffff`
            "
            :alt="slotProps.data.title"
            class="small_record_image"
            v-if="slotProps.data.media"
          />
          <img
            :src="`${$config.images.server}/images/noimage.jpg?width=80&height=80&fit=contain&background=ffffff`"
            class="record_image"
            v-else
          />
        </template>
      </Column>
      <Column field="title" header="Title" :sortable="true"></Column>
      <Column
        field="coordinates"
        header="Coordinates"
        :sortable="false"
      ></Column>
      <Column
        field="recordcategory"
        header="Categorie"
        :sortable="true"
      ></Column>
      <Column field="subtitle" header="Subtitle" :sortable="true"></Column>
      <Column field="keywords" header="Keywords" :sortable="true"></Column>
      <Column field="author" header="Author" :sortable="true"></Column>
      <Column field="updatedAt" header="Modified" :sortable="true"></Column>
      <Column>
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            @click="editrecord(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
  </div>

  <Dialog
    v-model:visible="recordDialog"
    :style="{ width: '450px' }"
    header="Piekirpedia point details"
    :maximizable="true"
    :modal="true"
    class="p-fluid"
    :draggable="true"
  >
    <p class="center-image">
      <img
        ref="currentImage"
        :src="
          `${$config.images.server}/images/${record.media}?width=150&height=150&fit=contain&background=ffffff`
        "
        :alt="record.title"
        class="big_record_image"
        v-if="record.media"
      />
      <img :src="`${$config.images.server}/images/noimage.jpg?width=150&height=150&fit=contain&background=ffffff`" class="record_image" v-else />
    </p>
    <div class="upload">
    </div>
    <div class="p-field">
      <label for="title">Title</label>
      <InputText
        id="title"
        v-model.trim="record.title"
        required="true"
        autofocus
        :class="{ 'p-invalid': submitted && !record.title }"
      />
      <small class="p-error" v-if="submitted && !record.title"
        >Title is required.</small
      >
    </div>
    <div class="p-field">
      <label for="subtitle">Subtitle</label>
      <InputText id="subtitle" v-model.trim="record.subtitle" required="true"         :class="{ 'p-invalid': submitted && !record.subtitle }"
      />
      <small class="p-error" v-if="submitted && !record.subtitle"
        >Subtitle is required.</small>
    </div>
    <div class="p-field">
      <label for="ddcategory">Point category</label>
      <Dropdown
        id="ddcategory"
        v-model="record.recordcategory"
        :options="categories"
        optionLabel="name"
        optionValue="code"
        placeholder="Select a category"
      />
    </div>
    <div class="p-field">
      <label for="ddconnected">Connected point</label>
      <Dropdown
        id="ddconnected"
        v-model="record.connectedId"
        :options="piekirpointsrecords"
        optionLabel="name"
        optionValue="id"
        placeholder="Select a point"
      />
    </div>
    <div class="p-field">
      <label for="ddcolor">Line color</label>
      <Dropdown
        id="ddcolor"
        v-model="record.color"
        :options="[{label: 'red', value: '#ff0000'},{label: 'blue', value: '#0000ff'},{label: 'yellow', value: '#ffff00'},{label: 'green', value: '#00ff00'}]"
        optionLabel="label"
        optionValue="value"
        placeholder="Select a color"
      />
    </div>
    <div class="p-field">
      <label for="ddstyle">Line style</label>
      <Dropdown
        id="ddstyle"
        v-model="record.style"
        :options="['dash','solid']"
        placeholder="Select a style"
      />
    </div>
    <div class="p-field">
      <label for="ddconnected">Linked piekirpedia record</label>
      <Dropdown
        id="ddconnected"
        v-model="record.linkedId"
        :options="piekirrecords"
        optionLabel="name"
        optionValue="id"
        placeholder="Select a record"
      />
    </div>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-10">
        <label for="coordinates">Coordinates (long;lat)</label>
        <InputText id="coordinates" v-model.trim="record.coordinates" required="true"         :class="{ 'p-invalid': submitted && !record.coordinates }"
      />
      <small class="p-error" v-if="submitted && !record.coordinates"
        >Coordinates are required.</small>
      </div>
      <div class="p-field p-col">
      </div>
    </div>
    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="hideDialog"
      />
    </template>
  </Dialog>
</template>

<script>
import recordService from "../../services/record_service"
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"
import mapboxgl from "mapbox-gl"
import config from "../../../config"

import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"
import Dropdown from "primevue/dropdown"

import BlotFormatter from 'quill-blot-formatter'
import {FilterMatchMode} from 'primevue/api';

export default {
  components: {
    Dropdown
  },
  setup: () => {
    const modules = {
      name: 'blotFormatter',  
      module: BlotFormatter, 
      options: {/* options */}
    }
    return { modules }
  },
  data() {
    return {
      map: null,
      marker: null,
      records: null,
      recordDialog: false,
      deleterecordDialog: false,
      record: {},
      submitted: false,
      isnew: false,
      files: [],
      displayUpload: false,
      displayChoose: false,
      coordinates: null,
      categories: [
        { name: "Publicatie", code: 1 },
        { name: "Biografische gebeurtenis", code: 2 },
        { name: "Publiek", code: 3 },
      ],
      piekirrecords: [{ name: "None", id: 0 }],
      piekirpointsrecords: [{ name: "None", id: 0 }],
      filters: null
    }
  },
  recordservice: null,
  created() {
    this.recordservice = new recordService()
    this.filters = {'global': {value: null, matchMode: FilterMatchMode.CONTAINS}}
  },
  mounted() {
    this.recordservice.getPiekirPoints_Admin().then((data) => {
      this.records = data
      data.forEach((item) => {
        this.piekirpointsrecords.push({
          name: item.title + " (" + item.subtitle + ")",
          id: item.id,
        })
      })
    })
    this.recordservice.getPiekirpedia().then((data) => {
      data.forEach((item) => {
        this.piekirrecords.push({
          name: item.title + " (" + item.subtitle + ")",
          id: item.id,
        })
      })
      this.piekirrecords.reverse()
    })

  },
  methods: {
    openUpload() {
      this.displayUpload = true
    },
    openChoose() {
      this.displayChoose = true
      if (!this.$refs.coodia.maximized) this.$refs.coodia.maximize()
    },
    onChooseShow() {
      let vm = this
      const nav = new mapboxgl.NavigationControl()
      mapboxgl.accessToken = config.mapbox.token //'pk.eyJ1IjoiamhmZWxlY3RyaWMiLCJhIjoiY2s5Y2w1aWxpMDN1cTNscjNhbnVyeXV3dyJ9.aNc2JuskzFcD_fjSkPwyIw';
      this.map = new mapboxgl.Map({
        container: "amap",
        style: config.mapbox.style, //'mapbox://styles/jhfelectric/ckkdhuvp50b4817my44r9ohmu',
        center:
          this.record.coordinates == null
            ? [0, 0]
            : JSON.parse(this.record.coordinates),
        zoom: 9,
      })
      this.marker = new mapboxgl.Marker({
        draggable: true,
      })
        .setLngLat(
          this.record.coordinates == null
            ? [0, 0]
            : JSON.parse(this.record.coordinates)
        )
        .addTo(this.map)
      //this.marker.on('dragend', this.onDragEnd);
      this.map.addControl(nav, "top-right")
      this.geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        marker: false,
      })
      this.geocoder.on("result", function(e) {
        vm.marker.setLngLat(e.result.center)
      })
      this.map.addControl(this.geocoder)
    },
    /*         onDragEnd() {
                var lngLat = this.marker.getLngLat();
                this.coordinates = 'Longitude: ' + lngLat.lng + '<br />Latitude: ' + lngLat.lat;
        },
 */ onChooseHide() {
      var lngLat = this.marker.getLngLat()
      this.record.coordinates = JSON.stringify([lngLat.lng, lngLat.lat])
      this.displayChoose = false
    },
    openNew() {
      this.record = {}
      this.submitted = false
      this.recordDialog = true
      this.isnew = true
    },
    hideDialog() {
      this.recordDialog = false
      this.submitted = false
    },
    saverecord(close) {
      this.submitted = true
      //defaults for tjalie_points
      this.record.recordtype = 11
      this.record.description = ""
      this.record.shortdescription = ""
      this.record.keywords = ""
      this.record.author = JSON.parse(localStorage.getItem("user")).username
      this.record.relatedmedia = "0"

      if (this.record.title.trim()) {
        if (this.record.id) {
          this.recordservice
            .update(this.record)
            .then(() => {
              this.recordservice
                .getPiekirPoints_Admin()
                .then((data) => (this.records = data))
              this.$toast.add({
                severity: "success",
                summary: "Successful",
                detail: "Point Updated",
                life: 3000,
              })
            })
            .catch((err) => {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: err,
                life: 3000,
              })
            })
        } else {
          this.recordservice
            .add(this.record)
            .then(() => {
              this.recordservice
                .getPiekirPoints_Admin()
                .then((data) => {
                  this.records = data
                  this.record = this.records[data.length-1]})
              this.$toast.add({
                severity: "success",
                summary: "Successful",
                detail: "Point Created",
                life: 3000,
              })
            })
            .catch((err) => {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: err,
                life: 3000,
              })
            })
        }
        if (close) {
          this.recordDialog = false
          this.record = {}
        }
      }
    },
    editrecord(record) {
      this.isnew = false
      this.record = { ...record }
      //this.$refs.editor.setHTML(this.record.description)
      this.recordDialog = true
    },
    confirmDeleterecord(record) {
      this.record = record
      this.deleterecordDialog = true
    },
    deleterecord() {
      this.recordservice
        .delete(this.record.id)
        .then(() => {
          this.recordservice.getPiekirPoints_Admin().then((data) => (this.records = data))
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Point deleted",
            life: 3000,
          })
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err,
            life: 3000,
          })
        })
      this.deleterecordDialog = false
      this.record = {}
    },
    onUpload(event) {
      this.record.media = event.files[0].name
      this.displayUpload = false
    },
    prepareUpload(event) {
      let user = JSON.parse(localStorage.getItem("user"))
      //event.xhr.open('POST', 'http://localhost:4000/records/upload', true);
      //event.xhr.withCredentials = true;
      event.xhr.setRequestHeader("Authorization", "Bearer " + user.token)
    },
  },
}
</script>

<style scoped>
/* .small_record_image {
    width: 100px;
}
.big_record_image {
    width: 300px;
}
 */
.basemap {
  width: 100%;
  height: 100%;
}
.center-image {
  text-align: center;
}
</style>
